// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1Db_TmBeoPK-nGzTETaq0E{width:525px;min-width:525px}._2LlmMGcB3RdHfSdUZxkXtp{display:flex;justify-content:center;padding-top:15px;padding-bottom:15px}._3yJcXPlfFEyb15LWO7eTVS{padding-top:15px;padding-bottom:15px}", ""]);
// Exports
exports.locals = {
	"contentContainer": "_1Db_TmBeoPK-nGzTETaq0E",
	"iconContainer": "_2LlmMGcB3RdHfSdUZxkXtp",
	"textContainer": "_3yJcXPlfFEyb15LWO7eTVS"
};
module.exports = exports;
