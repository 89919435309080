export const AUTH_API_KEY_CREATE_OWN = "AUTH_API_KEY_CREATE_OWN";
export const AUTH_API_KEY_DELETE_OWN = "AUTH_API_KEY_DELETE_OWN";
export const AUTH_API_KEY_EDIT_OWN = "AUTH_API_KEY_EDIT_OWN";
export const AUTH_API_KEY_VIEW_ALL = "AUTH_API_KEY_VIEW_ALL";
export const AUTH_API_KEY_VIEW_OWN = "AUTH_API_KEY_VIEW_OWN";
export const AUTH_CUSTOM_REPORT_VIEW_CREATE_OWN = "AUTH_CUSTOM_REPORT_VIEW_CREATE_OWN";
export const AUTH_CUSTOM_REPORT_VIEW_CREATE_SHARED = "AUTH_CUSTOM_REPORT_VIEW_CREATE_SHARED";
export const AUTH_CUSTOM_REPORT_VIEW_DELETE_OWN = "AUTH_CUSTOM_REPORT_VIEW_DELETE_OWN";
export const AUTH_CUSTOM_REPORT_VIEW_DELETE_SHARED = "AUTH_CUSTOM_REPORT_VIEW_DELETE_SHARED";
export const AUTH_CUSTOM_REPORT_VIEW_EDIT_OWN = "AUTH_CUSTOM_REPORT_VIEW_EDIT_OWN";
export const AUTH_CUSTOM_REPORT_VIEW_EDIT_SHARED = "AUTH_CUSTOM_REPORT_VIEW_EDIT_SHARED";
export const AUTH_CUSTOM_REPORT_VIEW_VIEW = "AUTH_CUSTOM_REPORT_VIEW_VIEW";
export const AUTH_EMS_CREATE_ENTITLEMENT = "AUTH_EMS_CREATE_ENTITLEMENT";
export const AUTH_EMS_SYNCHRONIZE = "AUTH_EMS_SYNCHRONIZE";
export const AUTH_EMS_VIEW = "AUTH_EMS_VIEW";
export const AUTH_LICENSE_ASSIGN = "AUTH_LICENSE_ASSIGN";
export const AUTH_LICENSE_VIEW = "AUTH_LICENSE_VIEW";
export const AUTH_LICENSE_VIEW_OWN = "AUTH_LICENSE_VIEW_OWN";
export const AUTH_REPORT_IMPORT = "AUTH_REPORT_IMPORT";
export const AUTH_REPORT_TEMPLATE_CREATE = "AUTH_REPORT_TEMPLATE_CREATE";
export const AUTH_REPORT_TEMPLATE_DEFAULT = "AUTH_REPORT_TEMPLATE_DEFAULT";
export const AUTH_REPORT_TEMPLATE_DELETE = "AUTH_REPORT_TEMPLATE_DELETE";
export const AUTH_REPORT_TEMPLATE_EDIT = "AUTH_REPORT_TEMPLATE_EDIT";
export const AUTH_REPORT_TEMPLATE_VIEW = "AUTH_REPORT_TEMPLATE_VIEW";
export const AUTH_REPORT_VIEW = "AUTH_REPORT_VIEW";
export const AUTH_REPORT_EXPORT_ALL = "AUTH_REPORT_EXPORT_ALL";
export const AUTH_REPORT_DELETE = "AUTH_REPORT_DELETE";
export const AUTH_REPORT_EDIT = "AUTH_REPORT_EDIT";
export const AUTH_ROLE_CREATE = "AUTH_ROLE_CREATE";
export const AUTH_ROLE_DEFAULT = "AUTH_ROLE_DEFAULT";
export const AUTH_ROLE_DELETE = "AUTH_ROLE_DELETE";
export const AUTH_ROLE_EDIT = "AUTH_ROLE_EDIT";
export const AUTH_ROLE_VIEW = "AUTH_ROLE_VIEW";
export const AUTH_TENANT_ACCESS = "AUTH_TENANT_ACCESS";
export const AUTH_TENANT_CREATE = "AUTH_TENANT_CREATE";
export const AUTH_TENANT_DELETE = "AUTH_TENANT_DELETE";
export const AUTH_TENANT_EDIT = "AUTH_TENANT_EDIT";
export const AUTH_TENANT_SETTINGS = "AUTH_TENANT_SETTINGS";
export const AUTH_TENANT_VIEW = "AUTH_TENANT_VIEW";
export const AUTH_USER_CREATE = "AUTH_USER_CREATE";
export const AUTH_USER_DELETE = "AUTH_USER_DELETE";
export const AUTH_USER_EDIT = "AUTH_USER_EDIT";
export const AUTH_USER_VIEW = "AUTH_USER_VIEW";
export const AUTH_USER_GROUP_CREATE = "AUTH_USER_GROUP_CREATE";
export const AUTH_USER_GROUP_DELETE = "AUTH_USER_GROUP_DELETE";
export const AUTH_USER_GROUP_EDIT = "AUTH_USER_GROUP_EDIT";
export const AUTH_USER_GROUP_VIEW = "AUTH_USER_GROUP_VIEW";
export const AUTH_WORKFLOW_CREATE = "AUTH_WORKFLOW_CREATE";
export const AUTH_WORKFLOW_DEFAULT = "AUTH_WORKFLOW_DEFAULT";
export const AUTH_WORKFLOW_DELETE = "AUTH_WORKFLOW_DELETE";
export const AUTH_WORKFLOW_EDIT = "AUTH_WORKFLOW_EDIT";
export const AUTH_WORKFLOW_VIEW = "AUTH_WORKFLOW_VIEW";
export const AUTH_WORKFLOW_TEMPLATE_CREATE = "AUTH_WORKFLOW_TEMPLATE_CREATE";
export const AUTH_WORKFLOW_TEMPLATE_VIEW = "AUTH_WORKFLOW_TEMPLATE_VIEW";
export const AUTH_WORKFLOW_TEMPLATE_EDIT = "AUTH_WORKFLOW_TEMPLATE_EDIT";
export const AUTH_WORKFLOW_TEMPLATE_DELETE = "AUTH_WORKFLOW_TEMPLATE_DELETE";
export const AUTH_WORKFLOW_TEMPLATE_EXPORT = "AUTH_WORKFLOW_TEMPLATE_EXPORT";
export const AUTH_WORKFLOW_TEMPLATE_PUBLISH = "AUTH_WORKFLOW_TEMPLATE_PUBLISH";
export const AUTH_LICENSE_HISTORY_VIEW_OWN = "AUTH_LICENSE_HISTORY_VIEW_OWN";
export const AUTH_LICENSE_HISTORY_VIEW_ALL = "AUTH_LICENSE_HISTORY_VIEW_ALL";
export const AUTH_LICENSE_POOL_CREATE = "AUTH_LICENSE_POOL_CREATE";
export const AUTH_LICENSE_POOL_EDIT = "AUTH_LICENSE_POOL_EDIT";
export const AUTH_LICENSE_POOL_VIEW = "AUTH_LICENSE_POOL_VIEW";
export const AUTH_LICENSE_POOL_DELETE = "AUTH_LICENSE_POOL_DELETE";
export const AUTH_SSO_CONFIGURE = "AUTH_SSO_CONFIGURE";
export const AUTH_LICENSE_ALERT = "AUTH_LICENSE_ALERT";
