import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { LoadingIndicator } from "../loading-indicator/LoadingIndicator";
import style from "./generic-error-dialog.scss";
import Warning from "components/icons/Warning";
import Modal from "components/modal/Modal";
import { SUPPORT_EMAIL } from "domain/globalConstants";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";

import testIds from "testIds.json";

export interface Props {
    isOpen: boolean;
    hideModal: () => void;
    reDoAction: () => void;
    redoActionButtonText: string;
    loading: boolean;
}

const GenericErrorDialog = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const mailToLink = `mailto:${SUPPORT_EMAIL}`;

    return (
        <Modal isOpen={props.isOpen} hideModal={props.hideModal}>
            {props.isOpen && props.loading ? (
                <LoadingIndicator />
            ) : (
                <div className={style.contentContainer}>
                    <div className={style.iconContainer}>
                        <Warning color={theme.errorBackgroundColor} />
                    </div>
                    <div className={style.textContainer}>
                        <>
                            <span>{t("Common.genericErrorMessage")}</span>
                            <span>
                                <a href={mailToLink}>{t("Common.genericContactSupport")}</a>
                            </span>
                        </>
                    </div>
                    <div className={style.iconContainer}>
                        <button
                            className={classNames(buttons.primaryButton, buttons.medium, buttons.okButton)}
                            onClick={() => props.reDoAction()}
                            data-testid={testIds.common.dialog.retryButton}
                        >
                            {props.redoActionButtonText}
                        </button>
                        <button
                            className={classNames(buttons.secondaryButton, buttons.medium, buttons.okButton)}
                            onClick={() => props.hideModal()}
                            data-testid={testIds.common.dialog.closeButton}
                        >
                            {t("Common.cancel")}
                        </button>
                    </div>
                </div>
            )}
        </Modal>
    );
};
export default GenericErrorDialog;
