import classNames from "classnames";
import { Form, Formik, FormikProps } from "formik";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { object, string } from "yup";

import Warning from "components/icons/Warning";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import ConfirmationModal from "components/workflows/ConfirmationModal";
import style from "components/workflows/manage-workflow-dialog/manage-workflow-dialog.scss";
import WasmVisualEditorView, { ActionType } from "components/workflows/wasm-visual-editor/WasmVisualEditorView";
import { compareEditorVersionDtos } from "components/workflows/WorkflowUtil";
import { FeatureLicenseType } from "domain/users";
import { WorkflowEntityType } from "domain/workflows";
import {
    ManifestWorkflowEditor,
    Profile,
    profileToFeatureLicenseMap,
    profileToNameMap,
    WorkflowService,
} from "services/workflows/WorkflowService";
import { StoreState } from "store";
import { setUser } from "store/user";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";
import { hasOnlyValidWorkflowCharacters } from "utils/commonFunctions";

import testIds from "testIds.json";

enum DialogState {
    FETCHING_WORKFLOW_EDITORS,
    FETCHING_WORKFLOW_EDITORS_FAILED,
    FETCHING_WORKFLOW,
    FETCHING_WORKFLOW_FAILED,
    SELECTING_WORKFLOW_EDITOR,
    EDITING,
}

interface Props {
    confirmCancelVisible?: boolean;
    entityType: WorkflowEntityType;
    onCancel: (doCancel: boolean) => void;
    onUpdateParentView: () => void;
    setDynamicSize: (initial: boolean) => void;
    setFullscreen: (fullscreen: boolean) => void;
    workflowService: WorkflowService;
    isCreate?: boolean;
    isEdit?: boolean;
    name?: string;
    parentWorkflowsCount?: number;
    product?: string;
    setShowConfirmCancelDialog?: (show: boolean) => void;
}

interface WorkflowFormValues {
    name: string;
}

interface CreateWorkflowFormValues extends WorkflowFormValues {
    product: Profile;
    version: string;
}

const WORKFLOW_NAME_MAX_LENGTH = 255;

const mapState = (state: StoreState) => ({
    user: state.userReducer.user,
});
const connector = connect(mapState, { setUser });
const ManageWorkflowTemplateDialog: React.FunctionComponent<Props & ConnectedProps<typeof connector>> = (props) => {
    const { t } = useTranslation();
    const [dialogState, setDialogState] = React.useState(DialogState.FETCHING_WORKFLOW);
    const [optionList, setOptionList] = React.useState<JSX.Element[] | JSX.Element | undefined>(undefined);
    const [selectedProfile, setSelectedProfile] = React.useState<Profile | "">("");
    const [selectedVersion, setSelectedVersion] = React.useState<string | "">("");
    const [workflowEditors, setWorkflowEditors] = useState<ManifestWorkflowEditor[]>([]);
    const [workflowNameInputValue, setWorkflowNameInputValue] = React.useState("");
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const tenantDetails = useSelector(
        (state: StoreState) => state.tenantDetailsReducer.stack[state.tenantDetailsReducer.stack.length - 1]
    );
    const fetchWorkflowEditors = (profile: Profile): Promise<ManifestWorkflowEditor[]> => {
        setDialogState(DialogState.FETCHING_WORKFLOW);

        const abortController = new AbortController();
        abortControllers.push(abortController);

        return props.workflowService.fetchWorkflowEditors(profile, abortController);
    };

    const handleSelectedProfileChanged = (event: React.FormEvent<HTMLSelectElement>) => {
        setSelectedProfile(event.currentTarget.value as Profile);
    };

    const handleSelectedVersionChanged = (event: React.FormEvent<HTMLSelectElement>) => {
        setSelectedVersion(event.currentTarget.value);
        setSelectedProfile(selectedProfile as Profile);
    };

    const confirmWorkflowEditorSelection = () => {
        props.setShowConfirmCancelDialog && props.setShowConfirmCancelDialog(true);
        setDialogState(DialogState.FETCHING_WORKFLOW);
        props.setDynamicSize(false);
        props.setFullscreen(true);
        setDialogState(DialogState.EDITING);
    };

    const handleWorkflowNameInputChange = (event: React.FormEvent<HTMLInputElement>) => {
        setWorkflowNameInputValue(event.currentTarget.value);
    };

    useEffect(() => {
        fetchWorkflowEditors(Profile.ALL)
            .then((workflowEditors) => {
                setWorkflowEditors(workflowEditors.filter((each) => each.editorGeneration === "V2"));
                // Set default SelectedProfile based on tenant's workflow licences
                const filteredProfiles = Object.values(Profile).filter((each) => {
                    const featureLicense = profileToFeatureLicenseMap.get(each);
                    return (
                        Profile.ALL !== each &&
                        featureLicense != null &&
                        tenantDetails.featureLicenses.includes(featureLicense)
                    );
                });

                if (filteredProfiles.length === 0) {
                    throw new Error("Tenant has no feature licences");
                }

                setDialogState(DialogState.SELECTING_WORKFLOW_EDITOR);
            })
            .catch(() => setDialogState(DialogState.FETCHING_WORKFLOW_EDITORS_FAILED));
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, []);

    useEffect(() => {
        const sortedEditors = workflowEditors
            .filter((editor) =>
                selectedProfile == Profile.BDE ? editor.profile === selectedProfile : editor.profile != Profile.BDE
            )
            .sort(compareEditorVersionDtos);

        if (sortedEditors.length == 0) {
            return;
        }
        const deduplicatedVersions = sortedEditors.filter(
            (editor, index, self) => index === self.findIndex((each) => each.version === editor.version)
        );

        // Templates and template sub-workflows are always created with the latest editor version.
        const optionList = (
            <option key={deduplicatedVersions[0].version + ":" + deduplicatedVersions[0].profile}>
                {deduplicatedVersions[0].version}
            </option>
        );

        setOptionList(optionList);
    }, [selectedProfile, workflowEditors]);

    const confirmationModal = (
        <ConfirmationModal
            onCancel={props.onCancel}
            onUpdateParentView={props.onUpdateParentView}
            setShowConfirmCancelDialog={props.setShowConfirmCancelDialog}
            confirmCancelVisible={props.confirmCancelVisible}
        />
    );

    const deduceActionType = (): ActionType => {
        if (props.isCreate) {
            return "create";
        } else if (props.isEdit) {
            return "edit";
        } else {
            return "update";
        }
    };

    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    let content = <></>;
    switch (dialogState) {
        case DialogState.FETCHING_WORKFLOW_EDITORS:
            content = (
                <div className={props.isCreate ? style.fetchStatus : style.fetchEditorStatus}>
                    <LoadingIndicator />
                    <div className={style.loadingMessage}>
                        {t("ManageWorkflowDialog.fetchWorkflowEditors.loadingMessage")}
                    </div>
                    {confirmationModal}
                </div>
            );
            break;
        case DialogState.FETCHING_WORKFLOW:
            content = (
                <div className={props.isCreate ? style.fetchStatus : style.fetchEditorStatus}>
                    <LoadingIndicator />
                    <div className={style.loadingMessage}>{t("ManageWorkflowDialog.fetchWorkflow.loadingMessage")}</div>
                    {confirmationModal}
                </div>
            );
            break;
        case DialogState.FETCHING_WORKFLOW_EDITORS_FAILED:
            content = (
                <div className={props.isCreate ? style.fetchStatus : style.fetchEditorStatus}>
                    <div className={style.failureMessage}>
                        {t("ManageWorkflowDialog.fetchWorkflowEditors.failureMessage")}
                    </div>
                    {confirmationModal}
                </div>
            );
            break;
        case DialogState.FETCHING_WORKFLOW_FAILED:
            content = (
                <div className={props.isCreate ? style.fetchStatus : style.fetchEditorStatus}>
                    <div className={style.failureMessage}>{t("ManageWorkflowDialog.fetchWorkflow.failureMessage")}</div>
                    {confirmationModal}
                </div>
            );
            break;
        case DialogState.SELECTING_WORKFLOW_EDITOR:
            content = (
                <Formik
                    initialValues={{ product: "", version: "", name: "" }}
                    onSubmit={confirmWorkflowEditorSelection}
                    validationSchema={object().shape({
                        name: string()
                            .required(t("WorkflowsTable.actions.create.validation.nameRequired"))
                            .max(WORKFLOW_NAME_MAX_LENGTH, t("WorkflowsTable.actions.create.validation.nameMaxLength"))
                            .test(
                                "Has only valid characters",
                                t("WorkflowsTable.actions.create.validation.nameSpecialCharacters"),
                                (value) => hasOnlyValidWorkflowCharacters(value)
                            ),
                        product: string().required(t("WorkflowsTable.actions.create.validation.productRequired")),
                        version: string().required(t("WorkflowsTable.actions.create.validation.versionRequired")),
                    })}
                    validateOnChange={false}
                >
                    {({
                        values,
                        errors,
                        handleChange,
                        validateField,
                        validateForm,
                        setFieldValue,
                    }: FormikProps<CreateWorkflowFormValues>) => {
                        return (
                            <Form>
                                <div>
                                    <div className={classNames(form.formFields, style.formField)}>
                                        <div>
                                            <label
                                                htmlFor="product"
                                                className={classNames(form.label, style.fixedWidthLabel)}
                                            >
                                                {t("ManageWorkflowDialog.selectWorkflowEditor.productLabel")}
                                            </label>
                                        </div>

                                        <div>
                                            <select
                                                autoFocus
                                                name="product"
                                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                    handleChange(e);
                                                    setFieldValue("version", "");
                                                    setSelectedVersion("");
                                                    handleSelectedProfileChanged(e);
                                                }}
                                                onBlur={() => errors.product && validateField("product")}
                                                className={classNames(form.select, style.fixedWidthProduct)}
                                                value={values.product}
                                                data-testid={
                                                    testIds.workArea.workflows.manageWorkflowDialog
                                                        .productVersionSelection.productSelect
                                                }
                                            >
                                                <option key={"empty"} value={""}>
                                                    {t("ManageWorkflowDialog.selectWorkflowEditor.selectProduct")}
                                                </option>
                                                {Object.values(Profile).map((each, index) => {
                                                    if (
                                                        Profile.ALL !== each &&
                                                        tenantDetails.featureLicenses.includes(
                                                            profileToFeatureLicenseMap.get(each) as FeatureLicenseType
                                                        )
                                                    ) {
                                                        return (
                                                            <option key={index} value={each}>
                                                                {profileToNameMap.get(each)}
                                                            </option>
                                                        );
                                                    }
                                                })}
                                            </select>
                                            <div className={classNames(form.error, form.errorMultiLine)}>
                                                {errors.product}
                                            </div>
                                        </div>
                                        <div>
                                            <select
                                                name="version"
                                                value={values.version}
                                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                    handleChange(e);
                                                    handleSelectedVersionChanged(e);
                                                }}
                                                onBlur={() => errors.version && validateField("version")}
                                                className={classNames(form.select, style.fixedWidthVersion)}
                                                disabled={selectedProfile === ""}
                                                data-testid={
                                                    testIds.workArea.workflows.manageWorkflowDialog
                                                        .productVersionSelection.versionSelect
                                                }
                                            >
                                                <option key={"value"} value={""}>
                                                    {t("ManageWorkflowDialog.selectWorkflowEditor.selectVersion")}
                                                </option>
                                                {selectedProfile !== "" && optionList}
                                            </select>
                                            <div className={classNames(form.error, form.errorMultiLine)}>
                                                {errors.version}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classNames(form.formFields, style.formField)}>
                                        <div>
                                            <label
                                                htmlFor="product"
                                                className={classNames(form.label, style.fixedWidthLabel)}
                                            >
                                                {t("Common.name")}
                                            </label>
                                        </div>
                                        <div>
                                            <input
                                                id="versionSelectWorkflowName"
                                                name="name"
                                                data-testid={testIds.workArea.workflows.manageWorkflowDialog.nameInput}
                                                type="text"
                                                className={classNames(form.input, style.fixedWidthInput)}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    handleChange(e);
                                                    handleWorkflowNameInputChange(e);
                                                }}
                                                onKeyUp={() => validateField("name")}
                                                maxLength={WORKFLOW_NAME_MAX_LENGTH}
                                            />

                                            <div className={classNames(form.error, form.errorMultiLine)}>
                                                {errors.name}
                                            </div>
                                        </div>
                                    </div>

                                    {(errors.name || errors.version || errors.product) && (
                                        <div className={classNames(form.errorMultiLine, style.commonError)}>
                                            <span className={style.localBannerInfoIcon}>
                                                <Warning color={theme.errorBackgroundColor} width={18} height={18} />
                                            </span>
                                            {t("ManageWorkflowDialog.selectWorkflowEditor.commonValidationError")}
                                        </div>
                                    )}
                                    <div className={classNames(form.buttonContainer)}>
                                        <button
                                            type="submit"
                                            className={classNames(
                                                [buttons.primaryButton, buttons.medium],
                                                style.button
                                            )}
                                            data-testid={
                                                testIds.workArea.workflows.manageWorkflowDialog.productVersionSelection
                                                    .designWorkflowButton
                                            }
                                            onClick={() => validateForm().then(() => confirmWorkflowEditorSelection)}
                                        >
                                            {t("ManageWorkflowDialog.selectWorkflowEditor.designWorkflowButton")}
                                        </button>
                                    </div>
                                </div>
                                {confirmationModal}
                            </Form>
                        );
                    }}
                </Formik>
            );
            break;
        case DialogState.EDITING:
            content = (
                <>
                    <WasmVisualEditorView
                        key={workflowNameInputValue}
                        profile={(props.product as Profile) || selectedProfile}
                        version={selectedVersion}
                        workflowEditors={workflowEditors}
                        uuid={undefined}
                        data-testid={testIds.workArea.workflows.manageWorkflowDialog.visualEditor.itself}
                        workflowName={workflowNameInputValue}
                        actionType={deduceActionType()}
                        entityType={props.entityType}
                        bmdeDiagnostics={selectedProfile}
                        preventNameChange={false}
                    />
                    {confirmationModal}
                </>
            );
            break;
    }
    return content;
};

export default connector(ManageWorkflowTemplateDialog);
