import classNames from "classnames";
import * as React from "react";
import { Menu, MenuItem } from "react-aria-menubutton";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { toast } from "react-toastify";

import style from "./template-kebab-menu.scss";
import DeleteConfirmationDialog from "components/confirmation/DeleteConfirmationDialog";
import { CustomToastCloseButton } from "components/icons/CustomToastCloseButton";
import Delete from "components/icons/Delete";
import Edit from "components/icons/Edit";
import Export from "components/icons/Export";
import { SuccessIcon } from "components/icons/SuccessIcon";
import layout from "components/layout/layout.scss";
import MenuItemButton from "components/menu-item-button/MenuItemButton";
import Modal from "components/modal/Modal";
import TextWithTooltip from "components/table/TextWithTooltip";
import {
    AUTH_WORKFLOW_TEMPLATE_DELETE,
    AUTH_WORKFLOW_TEMPLATE_EDIT,
    AUTH_WORKFLOW_TEMPLATE_EXPORT,
} from "domain/authority";
import { ConnectedWorkflows } from "domain/workflows";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { userSessionService } from "services/user/UserSessionService";
import { templateService } from "services/workflows/TemplateService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";

import testIds from "testIds.json";

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);
const TemplatesKebabMenu = (
    props: ConnectedProps<typeof connector> & {
        uuid: string;
        name: string;
        connectedWorkflows: ConnectedWorkflows;
        onDelete: () => void;
        isSubWorkflow: boolean;
    }
): JSX.Element => {
    const { t } = useTranslation();
    const [deleteWarningDialog, setDeleteWarningDialog] = React.useState(false);
    const [deleteTemplateModalVisible, setDeleteTemplateModalVisible] = React.useState(false);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    React.useEffect(() => {
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, []);

    const theme = useSelector((state: StoreState) => state.themeReducer.theme);

    const deleteTemplate = () => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        templateService
            .deleteTemplate(props.uuid, abortController)
            .then(() => {
                setDeleteTemplateModalVisible(false);
                toast(
                    <SuccessIcon
                        successClass={layout.customToastSuccessIcon}
                        color={theme.contentBackgroundColor}
                        text={t("WorkflowTemplates.deleteTemplate.successMessage", { name: props.name })}
                    />,
                    {
                        closeButton: (closeToastProps) => (
                            <CustomToastCloseButton closeToast={{ ...closeToastProps }} color={theme.iconFillColor} />
                        ),
                        className: layout.customToastSuccessMessage,
                    }
                );
                props.onDelete();
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setDeleteTemplateModalVisible(false);
                    toast(
                        <SuccessIcon
                            successClass={layout.customToastSuccessIcon}
                            color={theme.contentBackgroundColor}
                            text={t("WorkflowTemplates.deleteTemplate.failureMessage")}
                        />,
                        {
                            closeButton: (closeToastProps) => (
                                <CustomToastCloseButton
                                    closeToast={{ ...closeToastProps }}
                                    color={theme.iconFillColor}
                                />
                            ),
                            className: layout.customToastSuccessMessage,
                        }
                    );
                }
            });
    };
    const handleTemplateDelete = () => {
        deleteTemplate();
    };

    return (
        <div className={style.templatesIcon}>
            <Menu className={style.kebabMenu}>
                <ul>
                    {userSessionService.userHasAllAuthorities([AUTH_WORKFLOW_TEMPLATE_EDIT]) && (
                        <li>
                            <TextWithTooltip text={t("WorkflowTemplates.template.tooltipEdit")}>
                                <MenuItemButton
                                    onClick={() => {
                                        // TODO: Implement Edit Logic
                                    }}
                                    data-testid={testIds.workArea.workflowTemplates.table.kebabMenu.editButton}
                                >
                                    <Edit color={props.theme.iconFillColor} />
                                </MenuItemButton>
                            </TextWithTooltip>
                        </li>
                    )}
                    {userSessionService.userHasAllAuthorities([AUTH_WORKFLOW_TEMPLATE_EXPORT]) && (
                        <li>
                            <TextWithTooltip text={t("WorkflowTemplates.template.tooltipExport")}>
                                <MenuItemButton
                                    onClick={() => {
                                        //TODO : Export logic goes here
                                    }}
                                    data-testid={testIds.workArea.workflowTemplates.table.kebabMenu.exportButton}
                                >
                                    <Export color={props.theme.iconFillColor} />
                                </MenuItemButton>
                            </TextWithTooltip>
                        </li>
                    )}
                    {userSessionService.userHasAllAuthorities([AUTH_WORKFLOW_TEMPLATE_DELETE]) && (
                        <li>
                            <TextWithTooltip text={t("WorkflowTemplates.deleteTemplate.tooltipDelete")}>
                                <MenuItem
                                    tag="button"
                                    onKeyDown={undefined}
                                    onClick={() => {
                                        usageStatisticsService.sendEvent({
                                            category: Category.WORKFLOW_TEMPLATES,
                                            action: Action.REMOVE_WORKFLOW_TEMPLATE,
                                        });

                                        props.connectedWorkflows.parentWorkflows.length
                                            ? setDeleteWarningDialog(true)
                                            : setDeleteTemplateModalVisible(true);
                                    }}
                                    data-testid={testIds.workArea.workflows.table.kebabMenu.deleteButton}
                                >
                                    <Delete color={props.theme.iconFillColor} />
                                </MenuItem>
                            </TextWithTooltip>
                        </li>
                    )}
                </ul>
            </Menu>

            <Modal
                isOpen={deleteWarningDialog}
                hideModal={() => {
                    setDeleteWarningDialog(false);
                }}
                modalTitle={
                    props.isSubWorkflow
                        ? t("WorkflowTemplates.subWorkflows.deleteSubWorkflowWarningDialog.title")
                        : t("WorkflowTemplates.deleteTemplate.deleteTemplateWarningDialog.title")
                }
            >
                <>
                    <div className={style.editMessage}>
                        {t("WorkflowTemplates.deleteTemplate.deleteTemplateWarningDialog.message")}:
                    </div>
                    <div className={style.listBox}>
                        <ul>
                            {props.connectedWorkflows.parentWorkflows.map((each) => (
                                <li key={each}>{each}</li>
                            ))}
                        </ul>
                    </div>
                    <div className={style.okWarningButton}>
                        <button
                            className={classNames(buttons.primaryButton, buttons.medium, style.okButton)}
                            onClick={() => {
                                setDeleteWarningDialog(false);
                            }}
                            data-testid={testIds.common.confirmationDialog.confirmButton}
                        >
                            {t("Common.ok")}
                        </button>
                    </div>
                </>
            </Modal>
            <DeleteConfirmationDialog
                modalDisplayed={deleteTemplateModalVisible}
                introductionMessage={
                    <div>
                        <p>
                            {t("WorkflowTemplates.deleteTemplate.introductionMessagePre")}
                            <b>{props.name}</b>
                            {t("WorkflowTemplates.deleteTemplate.introductionMessagePost")}
                        </p>
                    </div>
                }
                confirmationMessage={t("WorkflowTemplates.deleteTemplate.confirmationMessage")}
                handleCommand={handleTemplateDelete}
                setModalDisplayed={() => setDeleteTemplateModalVisible(false)}
                title={t("WorkflowTemplates.deleteTemplate.title")}
            />
        </div>
    );
};

export default connector(TemplatesKebabMenu);
