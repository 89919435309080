import classNames from "classnames";
import { ErrorMessage, Form, Formik, FormikConfig, FormikProps } from "formik";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { object, string } from "yup";

import style from "./add-entitlement.scss";
// import { DeleteIcon } from "components/icons/DeleteIcon";
import Info from "components/icons/Info";
import SimpleDeleteIcon from "components/icons/SimpleDeleteIcon";
import {
    BLANCCO_TOKEN_ID,
    createProductIdToNameMap,
    FEATURE_LICENSES,
    isSubscriptionLicense,
} from "components/licenses/common";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import StaticTable from "components/support/api-guide/StaticTable";
import { deduceTierLicenses } from "components/tenants/add-tenant/AddTenantForm";
import Tooltip from "components/tooltip/Tooltip";
import Heading from "components/typography/heading/Heading";
import { Container, EmsConfiguration, EntitlementType, LicenseData } from "domain/licenses";
import { LicensingModel } from "domain/tenants";
import { licenseService, ProductRateList } from "services/licenses/LicenseService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { getCurrentTenantDetails } from "services/tenants/tenantCookieService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";
import { isAddOn } from "utils/commonFunctions";
import { logger } from "utils/logging";

import testIds from "testIds.json";

const DEFAULT_SELECT_CONTAINER_VALUE = "select_container";

interface Props {
    submitEventHandler: (values: FormValues, licenses: LicenseCount[]) => void;
    fetchedTokenRates: ProductRateList[];
    emsConfiguration: EmsConfiguration;
    availableLicenses: LicenseData[];
    slContainers: Container[];
}

export interface LicenseCount {
    productId: string;
    available: number;
    assign: number;
    index: number;
    loading: boolean;
    tokenUsed: number;
}

export interface FormValues {
    type: string;
    description: string;
    tenantUuid?: string;
    containerId?: string;
}

const connector = connect((state: StoreState) => ({
    tenantUuid: state.userReducer.user?.tenantUuid,
    theme: state.themeReducer.theme,
}));

function createLicenseName(product: string, type?: string): string {
    if (type?.trim() === "") {
        return product;
    } else {
        return product + " - " + type;
    }
}

function AddEntitlementForm(props: Props & ConnectedProps<typeof connector>): JSX.Element {
    const { t } = useTranslation();
    const DESCRIPTION_MAX_LENGTH = 64;
    const MAXIMUM_ASSIGNED_ENTITLEMENTS = 300_000;

    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [availableLicenses, setAvailableLicenses] = React.useState<LicenseData[]>([]);
    const [blanccoTokenLicense, setBlanccoTokenLicense] = React.useState<LicenseData | undefined>();
    const [licenses, setLicenses] = React.useState<LicenseCount[]>([]);
    const [invalidAssignedAmount, setInvalidAssignedAmount] = React.useState(false);
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const ALL_PRODUCTS = createProductIdToNameMap();
    const [initialTokenAmount, setInitialTokenAmount] = React.useState(0);
    const [licensesAvailable, setLicensesAvailable] = React.useState(false);
    const [licensesSorted, setLicensesSorted] = React.useState(false);

    const fetchAvailableLicenses = (licenseType: string, index: number) => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        setLicenses(
            licenses.map((license) => {
                if (license.index === index) {
                    license.loading = true;
                }
                return license;
            })
        );
        licenseService
            .fetchAvailableLicenses(licenseType, abortController)
            .then((data) => {
                setLicenses(
                    licenses.map((license) => {
                        if (license.index === index) {
                            license.productId = licenseType;
                            license.available = data.availableLicenses;
                            license.loading = false;
                        }
                        return license;
                    })
                );
            })
            .catch((err) => {
                logger.error("Exception occurred while fetching available licenses: ", err);
                setLicenses(
                    licenses.map((license) => {
                        if (license.index == index) {
                            license.loading = false;
                        }
                        return license;
                    })
                );
            });
    };

    React.useEffect(() => {
        reCalculateTokens();
    }, [licenses]);

    const submitHandler: FormikConfig<FormValues>["onSubmit"] = async (values, { setSubmitting }) => {
        usageStatisticsService.sendEvent({
            category: Category.LICENSE_ENTITLEMENT,
            action: Action.ADD_ENTITLEMENT,
        });
        await props.submitEventHandler(values, licenses);
        setSubmitting(true);
    };

    React.useEffect(() => {
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, []);

    React.useEffect(() => {
        if (getCurrentTenantDetails().licensingModel !== LicensingModel.BUNDLE_WITH_TOKEN) {
            setAvailableLicenses(props.availableLicenses);
            return;
        }
        const tenantLicenses: LicenseData[] = [];
        props.availableLicenses.forEach((license) => {
            const valid = new Date() < new Date(license.expirationDate);
            if (license.type === BLANCCO_TOKEN_ID && license.available > 0 && valid) {
                setBlanccoTokenLicense(license);
                setInitialTokenAmount(license.available);
            } else {
                if (
                    !isNaN(parseInt(license.type)) &&
                    license.available > 0 &&
                    valid &&
                    !isSubscriptionLicense(license.type) &&
                    isAddOn(license.type)
                ) {
                    tenantLicenses.push(license);
                }
            }
        });
        setAvailableLicenses(tenantLicenses);
    }, []);

    React.useEffect(() => {
        const deducedTierLicenses = deduceTierLicenses(getCurrentTenantDetails().tenantTier, true);
        if (!licensesAvailable && blanccoTokenLicense && deducedTierLicenses) {
            const licenseData = deducedTierLicenses
                .filter((each) => !FEATURE_LICENSES.includes(each))
                .map((each) => {
                    return {
                        product: ALL_PRODUCTS.get(each) || each,
                        type: each,
                        license: "",
                        available: blanccoTokenLicense.available,
                        total: 0,
                        expirationDate: "",
                        licenseType: "",
                        isFeatureLicensePresent: false,
                    };
                });
            availableLicenses.push(...licenseData);
            setLicensesAvailable(true);
        }
    }, [blanccoTokenLicense]);

    React.useEffect(() => {
        if (availableLicenses.length > 0 && !licensesSorted) {
            const sortedAvailableLicenses = [...availableLicenses].sort((l1, l2) => {
                const productA = l1.product.toUpperCase();
                const productB = l2.product.toUpperCase();

                if (productA < productB) {
                    return -1;
                }
                if (productA > productB) {
                    return 1;
                }
                return 0;
            });
            setAvailableLicenses(sortedAvailableLicenses);
            setLicensesSorted(true);
            setLicenses([
                {
                    productId: availableLicenses[0].type,
                    available: availableLicenses[0].available,
                    assign: 1,
                    index: 0,
                    loading: false,
                    tokenUsed:
                        blanccoTokenLicense && !isAddOn(availableLicenses[0].type)
                            ? tokenRate(availableLicenses[0].type)
                            : availableLicenses[0].available,
                },
            ]);
        }
    }, [availableLicenses]);

    const changeLicenseType = (productId: string, index: number) => {
        fetchAvailableLicenses(productId, index);
    };

    const hideAddRowButton = (): boolean => {
        if (blanccoTokenLicense) {
            return blanccoTokenLicense.available <= 0 || availableLicenses.length === licenses.length;
        }
        return availableLicenses.length === licenses.length;
    };

    const addRow = () => {
        usageStatisticsService.sendEvent({
            category: Category.LICENSE_ENTITLEMENT,
            action: Action.ADD_ENTITLEMENT_LICENSE,
        });

        // For the new row, first available license that was not selected previously will be assigned.
        let availableItem: LicenseData | undefined;
        availableLicenses.forEach((item) => {
            if (licenses.find((selectedLicense) => item.type === selectedLicense.productId) === undefined) {
                availableItem = item;
            }
        });

        // availableItem is undefined in case all the available licenses have been selected.
        // In this case, the button is hidden.
        setLicenses(
            licenses.concat([
                {
                    productId: availableItem?.type ?? "",
                    available: availableItem?.available ?? 0,
                    assign: 1,
                    index: (licenses.length > 0 ? Math.max(...licenses.map((item) => item.index)) : 0) + 1,
                    loading: false,
                    tokenUsed:
                        blanccoTokenLicense && !isAddOn(availableLicenses[0].type)
                            ? tokenRate(availableLicenses[0].type)
                            : availableLicenses[0].available,
                },
            ])
        );
    };

    const clearAllRows = () => {
        usageStatisticsService.sendEvent({
            category: Category.LICENSE_ENTITLEMENT,
            action: Action.REMOVE_ALL_LICENSES,
        });
        setLicenses([]);
    };

    const removeRow = (index: number) => {
        if (blanccoTokenLicense) {
            licenses.forEach((license) => {
                if (license.index === index && !isAddOn(license.productId)) {
                    setBlanccoTokenLicense({
                        ...blanccoTokenLicense,
                        available:
                            blanccoTokenLicense.available +
                            (isNaN(license.assign) ? 0 : license.assign) * tokenRate(license.productId),
                    });
                }
            });
        }

        setLicenses(licenses.filter((license) => license.index !== index));
    };

    const createAssignTokenErrorMessage = (amount: number, availableToken: number) => {
        let message;
        if (isNaN(amount)) {
            message = t("AddEntitlementForm.table.assignEntitlementsRequired");
        }
        if (amount < 1) {
            message = t("AddEntitlementForm.table.minimumNumberOfAssignedEntitlements");
        }
        const maximum = Math.min(MAXIMUM_ASSIGNED_ENTITLEMENTS, availableToken);
        if (0 > maximum) {
            message = t("AddEntitlementForm.table.maximumNumberOfAssignedTokens", {
                maximumAmount: initialTokenAmount,
            });
        }

        if (typeof message === "undefined") {
            setInvalidAssignedAmount(false);
            return null;
        }
        setInvalidAssignedAmount(true);

        return (
            <div className={classNames(form.error, form.errorMultiLine)}>
                <span className={style.text}>{message}</span>
            </div>
        );
    };

    const createAssignAmountErrorMessage = (amount: number, available: number) => {
        let message;
        if (isNaN(amount)) {
            message = t("AddEntitlementForm.table.assignEntitlementsRequired");
        }
        if (amount < 1) {
            message = t("AddEntitlementForm.table.minimumNumberOfAssignedEntitlements");
        }
        const maximum = Math.min(MAXIMUM_ASSIGNED_ENTITLEMENTS, available);
        if (amount > maximum) {
            message = t("AddEntitlementForm.table.maximumNumberOfAssignedEntitlements", {
                maximumAmount: maximum,
            });
        }

        if (typeof message === "undefined") {
            setInvalidAssignedAmount(false);
            return null;
        }
        setInvalidAssignedAmount(true);

        return (
            <div className={classNames(form.error, form.errorMultiLine)}>
                <span className={style.text}>{message}</span>
            </div>
        );
    };

    const reCalculateTokens = () => {
        if (blanccoTokenLicense) {
            if (licenses.length == 0) {
                setBlanccoTokenLicense((prevBlanccoTokenLicense: LicenseData) => {
                    return {
                        ...prevBlanccoTokenLicense,
                        available: initialTokenAmount,
                    };
                });
            }

            setBlanccoTokenLicense((prevBlanccoTokenLicense: LicenseData) => {
                const totalTokensToDeduct = licenses.reduce((accumulatedToken, license) => {
                    const productTokenRate = tokenRate(license.productId);
                    if (isNaN(license.assign)) {
                        return accumulatedToken;
                    }
                    return accumulatedToken + license.assign * productTokenRate;
                }, 0);

                return {
                    ...prevBlanccoTokenLicense,
                    available: initialTokenAmount - totalTokensToDeduct,
                };
            });
        }
    };

    const computeAvailableAmount = (availableLicenses: number, assignedAmount: number) => {
        if (isNaN(assignedAmount)) {
            return availableLicenses;
        } else {
            return availableLicenses - assignedAmount;
        }
    };

    const computeAvailableTokenAmount = (productId: string, assignedAmount: number) => {
        if (isNaN(assignedAmount)) {
            return 0;
        } else {
            return tokenRate(productId) * assignedAmount;
        }
    };

    const disableButton = (): boolean => {
        return licenses.length < 1 || invalidAssignedAmount;
    };

    const tokenRate = (productId: string) => {
        const product = props.fetchedTokenRates.find((p) => p.productId === productId);
        return product ? Number(product.rate) / 100 : 0;
    };

    // Helper functions
    const renderTokenLicenseContent = (license: LicenseCount, blanccoTokenLicense: LicenseData) => {
        if (isAddOn(license.productId)) {
            return (
                <>
                    {isNaN(license.assign) ? "0" : license.assign.toString()}
                    {createAssignAmountErrorMessage(license.assign, license.available)}
                </>
            );
        }
        return (
            <div>
                {computeAvailableTokenAmount(license.productId, license.assign).toString()}
                {createAssignTokenErrorMessage(license.assign, blanccoTokenLicense.available)}
            </div>
        );
    };

    const renderNonTokenLicenseContent = (license: LicenseCount) => {
        return computeAvailableAmount(license.available, license.assign).toString();
    };

    return (
        <Formik
            initialValues={{
                type: props.emsConfiguration.slEntitlements ? "SL_UPDATE" : "HL_UPDATE",
                description: "",
                containerId: "",
            }}
            onSubmit={submitHandler}
            validationSchema={object().shape({
                type: string().required(t("AddEntitlementForm.typeRequired")),
                description: string().max(DESCRIPTION_MAX_LENGTH),
                containerId: string().when("type", {
                    is: EntitlementType.SL_UPDATE,
                    then: string()
                        .required(t("AddEntitlementForm.containerRequired"))
                        .test(
                            "Something selected",
                            t("AddEntitlementForm.containerRequired"),
                            (value) => value !== DEFAULT_SELECT_CONTAINER_VALUE
                        ),
                    otherwise: string().optional(),
                }),
            })}
            validateOnChange={true}
            validateOnBlur={true}
        >
            {({ values, errors, handleChange, handleBlur, isSubmitting }: FormikProps<FormValues>) => {
                if (isSubmitting) {
                    return <LoadingIndicator />;
                }
                return (
                    <Form>
                        <Heading
                            tag="div"
                            variant="SUBTITLE_1"
                            className={style.modalSubHeading}
                            disableTopSpacing={true}
                        >
                            {t("AddEntitlementForm.addEntitlementFormSubtitle")}
                        </Heading>
                        <div className={form.formFields}>
                            <label
                                htmlFor="type"
                                className={classNames(form.label, {
                                    [form.inputError]: errors.type,
                                })}
                            >
                                {t("AddEntitlementForm.type")}
                            </label>
                            <select
                                id="type"
                                className={classNames(form.select, form.fixedWidthInput, {
                                    [form.inputError]: errors.type,
                                })}
                                onChange={(e) => {
                                    usageStatisticsService.sendEvent({
                                        category: Category.LICENSE_ENTITLEMENT,
                                        action: Action.CHANGE_ENTITLEMENT_TYPE,
                                        label: e.target.value,
                                    });
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                                value={values.type}
                                data-testid={
                                    testIds.workArea.license.entitlements.createEntitlementDialog.typeSelect.itself
                                }
                            >
                                <>
                                    {props.emsConfiguration.hlEntitlements && (
                                        <option key={EntitlementType.HL_UPDATE} value={EntitlementType.HL_UPDATE}>
                                            {t("Entitlements.type.hlUpdate")}
                                        </option>
                                    )}
                                    {props.emsConfiguration.slEntitlements && (
                                        <option key={EntitlementType.SL_UPDATE} value={EntitlementType.SL_UPDATE}>
                                            {t("Entitlements.type.slUpdate")}
                                        </option>
                                    )}
                                    {props.emsConfiguration.slEntitlements &&
                                        props.emsConfiguration.availableSlActivations > 0 && (
                                            <option key={EntitlementType.SL_CREATE} value={EntitlementType.SL_CREATE}>
                                                {t("Entitlements.type.slCreate")}
                                            </option>
                                        )}
                                </>
                            </select>
                            <div
                                className={form.error}
                                data-testid={
                                    testIds.workArea.license.entitlements.createEntitlementDialog.typeSelect.errorLabel
                                }
                            >
                                <ErrorMessage name="type" />
                            </div>
                            {values.type === EntitlementType.SL_UPDATE ? (
                                <div className={classNames(form.formFields)}>
                                    <label
                                        htmlFor="containerId"
                                        className={classNames(form.label, {
                                            [form.inputError]: errors.containerId,
                                        })}
                                    >
                                        {t("AddEntitlementForm.containerId")}
                                    </label>
                                    <select
                                        id="containerId"
                                        className={classNames(form.select, form.fixedWidthInput, {
                                            [form.inputError]: errors.containerId,
                                        })}
                                        onChange={(e) => {
                                            usageStatisticsService.sendEvent({
                                                category: Category.LICENSE_ENTITLEMENT,
                                                action: Action.CHANGE_ENTITLEMENT_CONTAINER,
                                            });
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        value={values.containerId}
                                        data-testid={
                                            testIds.workArea.license.entitlements.createEntitlementDialog
                                                .containerSelect.itself
                                        }
                                    >
                                        <option
                                            key={DEFAULT_SELECT_CONTAINER_VALUE}
                                            value={DEFAULT_SELECT_CONTAINER_VALUE}
                                        >
                                            {t("AddEntitlementForm.selectContainer")}
                                        </option>
                                        {props.slContainers.map((container) => (
                                            <option key={container.containerId} value={container.containerId}>
                                                {container.name != null && container.name != ""
                                                    ? container.name
                                                    : container.containerId}
                                            </option>
                                        ))}
                                    </select>
                                    <div
                                        className={form.error}
                                        data-testid={
                                            testIds.workArea.license.entitlements.createEntitlementDialog
                                                .containerSelect.errorLabel
                                        }
                                    >
                                        <ErrorMessage name="containerId" />
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                            {values.type === EntitlementType.SL_CREATE ? (
                                <>
                                    <div>
                                        <label
                                            htmlFor="availableSlActivations"
                                            className={classNames(form.label, style.slActivationLabel)}
                                        >
                                            {t("AddEntitlementForm.availableSlActivations")}
                                        </label>
                                        <label id="availableSlActivations" className={form.label}>
                                            {props.emsConfiguration.availableSlActivations}
                                        </label>
                                    </div>
                                    <div>
                                        <label
                                            htmlFor="usedSlActivations"
                                            className={classNames(form.label, style.slActivationLabel)}
                                        >
                                            {t("AddEntitlementForm.usedSlActivations")}
                                        </label>
                                        <label id="usedSlActivations" className={form.label}>
                                            {props.emsConfiguration.usedSlActivations}
                                        </label>
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                        <div className={classNames(form.formFields, form.formFieldsFlex)}>
                            <div className={form.formFieldsAlignItemsTop}>
                                <span className={form.optional}>{t("Common.optional")}</span>
                                <label htmlFor="description" className={classNames(form.label)}>
                                    {t("AddEntitlementForm.description")}
                                </label>
                            </div>
                            <div className={classNames(style.gridRows, form.notes)}>
                                <textarea
                                    id="description"
                                    className={classNames(form.input, form.fixedWidthInput, form.textAreaHeight)}
                                    onChange={handleChange}
                                    data-testid={
                                        testIds.workArea.license.entitlements.createEntitlementDialog
                                            .descriptionTextArea.itself
                                    }
                                    maxLength={DESCRIPTION_MAX_LENGTH}
                                />
                                <span className={classNames(form.optional)}>
                                    {t("AddEntitlementForm.charactersRemaining", {
                                        remainingCharacters: (
                                            DESCRIPTION_MAX_LENGTH - values.description.length
                                        ).toString(),
                                        maximumNumberOfCharacters: DESCRIPTION_MAX_LENGTH.toString(),
                                    })}
                                </span>
                            </div>
                        </div>
                        <Heading tag="div" variant="SUBTITLE_1" className={style.modalSubHeading}>
                            {t("AddEntitlementForm.addEntitlementFormSubtitle2")}
                        </Heading>
                        {blanccoTokenLicense && (
                            <div>
                                <span className={style.tokenMessage}>
                                    {t("AddEntitlementForm.tokenMessage", {
                                        tokenAmount: initialTokenAmount.toString(),
                                    })}
                                </span>
                            </div>
                        )}
                        <StaticTable
                            headers={[
                                {
                                    className: style.columnHeader,
                                    value: t("AddEntitlementForm.table.license"),
                                },
                                blanccoTokenLicense
                                    ? {
                                          className: style.columnHeader,
                                          value: (
                                              <Tooltip content={t("AddEntitlementForm.table.tokenAmountTooltip")}>
                                                  <span className={style.info}>
                                                      <div className={style.infoText}>
                                                          {t("AddEntitlementForm.table.tokenAmount")}
                                                      </div>
                                                      <Info
                                                          borderColor={theme.contentBackgroundColor}
                                                          color={theme.iconFillColor}
                                                      />
                                                  </span>
                                              </Tooltip>
                                          ),
                                      }
                                    : {
                                          className: style.columnHeader,
                                          value: t("AddEntitlementForm.table.available"),
                                      },
                                {
                                    className: style.columnHeader,
                                    value: blanccoTokenLicense ? (
                                        <Tooltip content={t("AddEntitlementForm.table.amountToAssignTooltip")}>
                                            <span className={style.info}>
                                                <div className={style.infoText}>
                                                    {t("AddEntitlementForm.table.amountToAssign")}
                                                </div>
                                                <Info
                                                    borderColor={theme.contentBackgroundColor}
                                                    color={theme.iconFillColor}
                                                />
                                            </span>
                                        </Tooltip>
                                    ) : (
                                        t("AddEntitlementForm.table.amountToAssign")
                                    ),
                                },
                            ]}
                            cells={licenses.map((license) => {
                                let addOn = isAddOn(license.productId);

                                return [
                                    <>
                                        {blanccoTokenLicense && addOn && (
                                            <div className={classNames(style.addOnText)}>{t("Common.addOns")}</div>
                                        )}
                                        <select
                                            className={classNames(style.formElement, form.select)}
                                            id={"productId" + license.index}
                                            name={"productId" + license.index}
                                            key={"productId" + license.index}
                                            onChange={(e) => {
                                                usageStatisticsService.sendEvent({
                                                    category: Category.LICENSE_ENTITLEMENT,
                                                    action: Action.CHANGE_LICENSE_TYPE,
                                                    label: license.productId,
                                                });
                                                changeLicenseType(e.target.value, license.index);
                                                addOn = isAddOn(e.target.value);
                                            }}
                                            defaultValue={license.productId}
                                        >
                                            {availableLicenses.map((value) => (
                                                <option
                                                    key={value.type}
                                                    value={value.type}
                                                    defaultValue={license.productId}
                                                    hidden={
                                                        value.type !== license.productId &&
                                                        licenses.find((item) => item.productId === value.type) !==
                                                            undefined
                                                    }
                                                >
                                                    {createLicenseName(value.product, value.license)}
                                                </option>
                                            ))}
                                        </select>
                                    </>,
                                    license.loading ? (
                                        <LoadingIndicator small={true} />
                                    ) : blanccoTokenLicense ? (
                                        renderTokenLicenseContent(license, blanccoTokenLicense)
                                    ) : (
                                        renderNonTokenLicenseContent(license)
                                    ),
                                    <div
                                        className={classNames(form.formFieldsFlex, style.gridColumns)}
                                        key={license.index}
                                    >
                                        <span>
                                            <input
                                                autoFocus
                                                className={classNames(form.input, style.formElement)}
                                                key={"assign" + license.index}
                                                id={"assign" + license.index}
                                                type="number"
                                                onChange={(e) => {
                                                    // let oldAssign = 0;
                                                    setLicenses(
                                                        licenses.map((element) => {
                                                            if (element.productId == license.productId) {
                                                                // oldAssign = element.assign;
                                                                element.assign = parseInt(e.target.value);
                                                                element.tokenUsed =
                                                                    tokenRate(license.productId) *
                                                                    parseInt(e.target.value);
                                                            }
                                                            return element;
                                                        })
                                                    );
                                                }}
                                                min={1}
                                                disabled={license.loading}
                                                defaultValue={license.assign}
                                            />
                                            {!blanccoTokenLicense &&
                                                createAssignAmountErrorMessage(license.assign, license.available)}
                                        </span>
                                        <div
                                            key={"deleteRow" + license.index}
                                            data-testid={
                                                testIds.workArea.license.entitlements.createEntitlementDialog
                                                    .deleteRowButton
                                            }
                                            className={classNames(style.formElement, style.icon)}
                                            onClick={() => {
                                                usageStatisticsService.sendEvent({
                                                    category: Category.LICENSE_ENTITLEMENT,
                                                    action: Action.REMOVE_LICENSE,
                                                    label: license.productId,
                                                });
                                                removeRow(license.index);
                                            }}
                                        >
                                            <SimpleDeleteIcon color={props.theme.errorBackgroundColor} />
                                        </div>
                                    </div>,
                                ];
                            })}
                        />
                        <div className={classNames(style.gridRows, style.buttons)}>
                            <span className={style.gridColumns}>
                                <button
                                    // Prevent this button from acting as a form submit button when it's the only
                                    // enabled button. Reference: https://github.com/jaredpalmer/formik/issues/1610
                                    type="button"
                                    onClick={addRow}
                                    className={classNames(style.link, buttons.textButton)}
                                    data-testid={
                                        testIds.workArea.license.entitlements.createEntitlementDialog.addRowButton
                                    }
                                    hidden={hideAddRowButton()}
                                >
                                    {t("AddEntitlementForm.table.addRow")}
                                </button>

                                <button
                                    // See the comment for the above button.
                                    type="button"
                                    onClick={clearAllRows}
                                    className={classNames(style.link, buttons.textButton)}
                                    data-testid={
                                        testIds.workArea.license.entitlements.createEntitlementDialog.clearAllButton
                                    }
                                >
                                    {t("AddEntitlementForm.table.clearAll")}
                                </button>
                            </span>
                            <div className={form.buttonContainer}>
                                <button
                                    type="submit"
                                    className={
                                        disableButton()
                                            ? classNames(buttons.disabledButton, form.submitButton)
                                            : classNames(buttons.primaryButton, buttons.medium, form.submitButton)
                                    }
                                    disabled={disableButton()}
                                    data-testid={
                                        testIds.workArea.license.entitlements.createEntitlementDialog.createButton
                                    }
                                >
                                    {t("AddEntitlementForm.createEntitlementButton")}
                                </button>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default connector(AddEntitlementForm);
