import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import style from "./add-tenant.scss";
import { FormValues } from "./AddTenantForm";
import TenantAccessIcon from "components/icons/TenantAccessIcon";
import {
    createLicenseTypes,
    FEATURE_TENANT_MANAGEMENT,
    isExpirationDateValid,
    NON_DELIVERABLE_FEATURE_LICENSES,
} from "components/licenses/common";
import { License } from "components/licenses/delivery-history/DeliveryFormContent";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import { AddTenantForm } from "components/tenants/add-tenant/AddTenantForm";
import UnexpectedErrorMessage from "components/unexpected-error-message/UnexpectedErrorMessage";
import { LicenseData, LicenseDelivery, MAX_DATE } from "domain/licenses";
import { CombinedTier, LicensingModel, TenantType } from "domain/tenants";
import { TIER_TO_LICENSES_MAP } from "domain/tierRelatedMaps";
import { licenseService, ProductToRateList } from "services/licenses/LicenseService";
import { getCurrentTenantDetails } from "services/tenants/tenantCookieService";
import { CreateTenantResponse, tenantService } from "services/tenants/TenantService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import { formatDateWithoutTime, formatExpirationDate, formatIsoDate, formatUtcDateString } from "utils/format";
import { Logger } from "utils/logging";

import testIds from "testIds.json";

const LOGGER = new Logger("AddTenantView");

interface Result {
    title: string;
    message: string;
}

interface Props {
    onTenantAdded: () => void;
    onHideModal: () => void;
    parentLicenses: LicenseData[] | undefined;
    parentExpirationDate: Date | undefined;
    parentLevel: number;
    parentUuid?: string;
    tier: CombinedTier;
    type: TenantType;
    licensingModel: LicensingModel;
    rateVersions?: ProductToRateList[];
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

const AddTenantView = (props: ConnectedProps<typeof connector> & Props): JSX.Element => {
    const { t } = useTranslation();

    const [result, setResult] = React.useState<Result>({ title: "", message: "" });
    const [warning, setWarning] = React.useState<JSX.Element>(<></>);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [availableRegions, setAvailableRegions] = React.useState<string[]>([]);
    const [tenantCreationModalTitle, setTenantCreationModalTitle] = React.useState<string>(t("Common.pleaseWait"));
    const [licenses, setLicenses] = React.useState<LicenseData[]>([]);
    const LICENSE_TYPES = createLicenseTypes(true);
    const deliverableLicenseTypes = LICENSE_TYPES.filter(
        (element) => !NON_DELIVERABLE_FEATURE_LICENSES.includes(element.productId)
    );
    const [loading, setLoading] = React.useState(true);
    const hideAddTenantFormModal = () => {
        setAddTenantFormModalVisibility(false);
        props.onHideModal();
    };

    const isOptionalValue = props.parentUuid != undefined || getCurrentTenantDetails().type != "INTERNAL";

    const showResult = (resultToShow: Result) => {
        setResult(resultToShow);
        setResultVisible(true);
    };

    const hideResult = () => {
        setResultVisible(false);
        setResult({ title: "", message: "" });
        props.onTenantAdded();
        props.onHideModal();
    };

    React.useEffect(() => {
        return () => abortControllers.filter((value) => !value.signal.aborted).forEach((value) => value.abort());
    }, [licenses]);

    React.useEffect(() => {
        const fetchTenantData = async () => {
            let foundTenantManagementLicense = false;
            if (props.tier != undefined) {
                const tierLicenses = TIER_TO_LICENSES_MAP.get(props.tier);
                if (tierLicenses != undefined && tierLicenses.includes(FEATURE_TENANT_MANAGEMENT)) {
                    foundTenantManagementLicense = true;
                }
            }
            if (props.parentUuid != undefined && props.parentLicenses == undefined) {
                const licensesAbortController = new AbortController();
                abortControllers.push(licensesAbortController);
                try {
                    const fetchLicensesResponse = await licenseService.fetchLicenses(
                        licensesAbortController,
                        undefined,
                        undefined,
                        props.parentUuid
                    );
                    if (
                        foundTenantManagementLicense ||
                        fetchLicensesResponse.licenses.some((each) => each.type === FEATURE_TENANT_MANAGEMENT)
                    ) {
                        setLicenses(deriveTenantLicenses(deliverableLicenseTypes, fetchLicensesResponse.licenses));
                    } else {
                        setAddTenantFormModalVisibility(false);
                        showResult({
                            title: t("AddCustomerView.failure"),
                            message: t("AddCustomerView.licenseMissingError"),
                        });
                    }
                } catch (licensingError) {
                    setAddTenantFormModalVisibility(false);
                    showResult({
                        title: t("AddCustomerView.failure"),
                        message: t("AddCustomerView.fetchLicensesFailure"),
                    });
                }
            } else {
                setLicenses(deriveTenantLicenses(deliverableLicenseTypes, props.parentLicenses));
            }

            try {
                setAddTenantFormModalVisibility(true);

                const regionResponse = await tenantService.fetchRegionsList();
                setAvailableRegions(regionResponse.regions);
            } catch (error) {
                setAddTenantFormModalVisibility(false);
                showResult({
                    title: t("AddCustomerView.failure"),
                    message: t("AddCustomerView.fetchRegionFailure"),
                });
            }
        };
        fetchTenantData().then(() => {
            setLoading(false);
            setTenantCreationModalTitle(t("AddCustomerForm.title"));
        });
    }, []);

    const addTenantSubmitEventHandler = async (
        {
            customerName,
            type,
            region,
            tier,
            country,
            notes,
            mainContactName,
            email,
            expirationDate,
            salesforceAccountId,
            ems,
            deliveryDetails,
            licensingModel,
        }: FormValues,
        licensesToDeliver: License[]
    ): Promise<void> => {
        setLoading(true);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        const { signal } = abortController;
        let createTenantResponse: CreateTenantResponse;
        const date =
            expirationDate === undefined
                ? ""
                : formatUtcDateString(formatDateWithoutTime(expirationDate.toString()), 23, 59, 59);
        const licenseDelivery: LicenseDelivery = {
            type: "NEW_DEAL",
            caseNumber: deliveryDetails.caseNumber,
            opportunityId: deliveryDetails.opportunityId,
            notes: deliveryDetails.notes,
            tokenRateVersion: deliveryDetails.tokenRateVersion,
            licenses:
                licensesToDeliver.length == 1 && licensesToDeliver[0].licenseType == "default"
                    ? []
                    : licensesToDeliver.map((each) => {
                          return {
                              licenseId: each.licenseType,
                              expirationDate: formatUtcDateString(
                                  formatExpirationDate(each.expirationDate),
                                  23,
                                  59,
                                  59
                              ),
                              amount: each.licensesToAdd,
                              totalOfLicenses: each.totalOfLicenses,
                          };
                      }),
        };
        try {
            createTenantResponse = await tenantService.createTenant(
                customerName,
                type,
                region,
                tier,
                country,
                notes,
                mainContactName,
                email,
                date,
                salesforceAccountId,
                ems,
                props.parentUuid,
                licenseDelivery,
                licensingModel,
                abortController
            );
        } catch (e) {
            if (!signal.aborted) {
                showResult({
                    title: t("AddCustomerView.failure"),
                    message: getErrorMessage(e.message, customerName),
                });
                LOGGER.error("Failed to add a customer:", e);
                setLoading(false);
            }
            return;
        }
        if (signal.aborted) {
            return;
        }
        setAddTenantFormModalVisibility(false);
        setWarning(
            createTenantResponse.warnings ? (
                <div>
                    <strong>{t("AddCustomerView.warnings.multipleErrors")}</strong>
                    <ul>
                        {createTenantResponse.warnings.includes("USER_CREATION_FAILED") && (
                            <li>
                                {t("AddCustomerView.warnings.userCreationFailedMessage1")}
                                <TenantAccessIcon color={props.theme.iconFillColor} /> {")."}
                                <div>{t("AddCustomerView.warnings.userCreationFailedMessage2")}</div>
                            </li>
                        )}
                        {createTenantResponse.warnings.includes("LICENSE_DELIVERY_CREATION_FAILED") && (
                            <li>
                                {t("AddCustomerView.warnings.licenseCreationFailedMessage1")}
                                <TenantAccessIcon color={props.theme.iconFillColor} /> {")."}
                                <div>{t("AddCustomerView.warnings.licenseCreationFailedMessage2")}</div>
                            </li>
                        )}
                    </ul>
                </div>
            ) : (
                <></>
            )
        );
        showResult({
            title: t("AddCustomerView.successTitle"),
            message: t("AddCustomerView.successMessage", { customerName }),
        });
    };

    const getErrorMessage = (e: string, customerName: string): string => {
        const error = JSON.parse(e);
        const code = error.error.code;
        const requestId = error.error.requestId;
        let errorMessage = error.error.message;
        if (code == "TENANT_NAME_REQUIRED") {
            errorMessage = t("AddCustomerView.errorMessages.missingCustomerName");
        } else if (code == "INVALID_TENANT_NAME") {
            errorMessage = t("AddCustomerView.errorMessages.invalidCustomerName", { customerName: customerName });
        } else if (code == "TENANT_NAME_NOT_AVAILABLE") {
            errorMessage = t("AddCustomerView.errorMessages.conflictingCustomerName", {
                customerName: customerName,
            });
        } else if (code == "INVALID_NOTES") {
            errorMessage = t("AddCustomerView.errorMessages.invalidNotes");
        } else if (code == "INVALID_CONTACT_NAME") {
            errorMessage = t("AddCustomerView.errorMessages.invalidContactName");
        } else if (code == "INVALID_COUNTRY_CODE") {
            errorMessage = t("AddCustomerView.errorMessages.invalidCountryCode");
        } else if (code == "REGION_REQUIRED") {
            errorMessage = t("AddCustomerView.errorMessages.invalidRegion");
        } else if (code == "INVALID_EMAIL") {
            errorMessage = t("AddCustomerView.errorMessages.emailNotValid");
        } else {
            errorMessage = <UnexpectedErrorMessage requestId={requestId} />;
        }
        return errorMessage;
    };

    const [addTenantFormModalVisibility, setAddTenantFormModalVisibility] = React.useState(true);
    const [resultVisible, setResultVisible] = React.useState(false);

    return (
        <div>
            <Modal
                isOpen={addTenantFormModalVisibility}
                hideModal={hideAddTenantFormModal}
                modalTitle={tenantCreationModalTitle}
            >
                {loading ? (
                    <div className={style.loadingIndicator}>
                        <LoadingIndicator />
                    </div>
                ) : (
                    <div>
                        <AddTenantForm
                            submitEventHandler={addTenantSubmitEventHandler}
                            closeHandler={hideAddTenantFormModal}
                            regionsList={availableRegions}
                            theme={props.theme}
                            tenantLicenses={licenses}
                            parentExpirationDate={props.parentExpirationDate}
                            parentHierarchyLevel={props.parentLevel}
                            isUserDetailsOptional={isOptionalValue}
                            tenantTier={props.tier}
                            tenantType={props.type}
                            licensingModel={props.licensingModel}
                            rateVersions={props.rateVersions}
                        />
                    </div>
                )}
            </Modal>
            <Modal isOpen={resultVisible} hideModal={hideResult} modalTitle={result.title}>
                <div className={style.successMessageContainer}>{result.message}</div>
                <div className={style.warningMessageContainer}>{warning}</div>
                <div className={style.successButtonContainer}>
                    <button
                        className={classNames(buttons.primaryButton, buttons.medium, style.successButton)}
                        onClick={hideResult}
                        data-testid={testIds.common.dialog.closeButton}
                    >
                        {t("Common.ok")}
                    </button>
                </div>
            </Modal>
        </div>
    );
};

function createLicense(
    licenseType: string,
    available: number,
    productName: string,
    expirationDate: string,
    licensesToAdd: number,
    parentLicenseExpiration: string | null
): LicenseData {
    return {
        product: licenseType,
        license: productName,
        available: available,
        total: licensesToAdd,
        expirationDate: expirationDate,
        type: licenseType,
        licenseType: licenseType,
        isInvalid: parentLicenseExpiration != null ? formatIsoDate(new Date()) > parentLicenseExpiration : undefined,
        isFeatureLicensePresent: false,
    };
}

function deriveTenantLicenses(
    deliverableLicenseTypes: { productName: string; productId: string }[],
    tenantLicenses?: LicenseData[]
): LicenseData[] {
    if (tenantLicenses != null) {
        return tenantLicenses
            .filter((l) => isExpirationDateValid(l.expirationDate, MAX_DATE))
            .map((l) => [l, l.type === FEATURE_TENANT_MANAGEMENT ? l.available - 1 : l.available] as const)
            .filter(([, remaining]) => remaining > 0)
            .map(([license, remainingLicenses]) => {
                const productName =
                    deliverableLicenseTypes.find((each) => each.productId === license.type)?.productName ??
                    license.type;
                return createLicense(
                    license.type,
                    remainingLicenses,
                    productName,
                    license.expirationDate,
                    0,
                    license.expirationDate
                );
            });
    }

    return deliverableLicenseTypes
        .sort((a, b) => (a.productName < b.productName ? -1 : 1))
        .map((license) => createLicense(license.productId, 0, license.productName, formatIsoDate(MAX_DATE), 1, null));
}

export default connector(AddTenantView);
